<template>
	<v-sheet class="inventory" style="height: calc(100vh - 88px)">
		<v-row class="d-flex justify-space-between">
			<v-col md="12" class="d-flex justify-space-between">
				<template v-for="(row, index) in listingStatus">
					<v-col md="4" :key="index">
						<StatusCard
							:type="row.type"
							@changeStatus="changeStatus"
							:value="row.value"
							:heading="row.text"
							:statusCount="row.status_count"
							:statusColor="row.color"
							:textColor="row.textcolor"
						></StatusCard>
					</v-col>
				</template>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" text-color="white">
								{{ item.status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						v-model="statusOne"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0" v-if="statusOne">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="getPermission('product::create')"
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						:to="{ name: 'product-create' }"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn tile depressed color="blue darken-4 white--text" @click="importExcelFile()">
						<v-icon size="16">mdi-file-excel</v-icon>
						Import</v-btn
					>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>

					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="advanceSearch = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>

					<!-- <v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu> -->

					<v-menu
						v-model="statusTwo"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<template v-if="statusTwo">
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('inventory')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('inventory')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</template>
					</v-menu>
					<v-btn
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						v-on:click="exportDialogNew = true"
						tile
						depressed
					>
						<v-icon dark left>mdi-database-export</v-icon> Export
					</v-btn>
				</template>
			</v-col>
		</v-row>

		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Product #, Name"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="product"
			delete-endpoint="inventory/"
			detail-route="product-detail"
			update-route="product-update"
			v-on:reload:content="filterRows"
			delete-note="All transactions(purchase order products, quotation products excluding approved one) of this product will also be deleted."
		></Table>
		<ExportDialogNew
			endpoint="export-product/?type=supplier"
			title="Export Product"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<!-- <ExportDialog
			endpoint="inventory/export?type=inventory&current=1"
			title="Inventories"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="inventory/export?type=inventory"
			title="Inventories"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog> -->
		<ImportDialog
			endpoint="inventory/import?type=inventory"
			title="Inventories"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Product"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<Dialog
			:dialog="importExcelDialog"
			v-on:close="importExcelDialog = false"
			v-if="importExcelDialog"
		>
			<template v-slot:title> Upload Excel File </template>
			<template v-slot:body>
				<v-row>
					<v-col md="3" class="py-0">
						<label for="upload-file" class="btx-label mt-3">Select File</label>
					</v-col>
					<v-col md="9" class="py-0">
						<FileUpload
							:disabled="pageLoading"
							:loading="pageLoading"
							id="upload-file"
							placeholder="File"
							:allow-add-more="false"
							v-model="upload_excel.file"
						></FileUpload>
						<p class="mt-2 red--text" v-if="fileError">
							{{ fileError }}
						</p>
						<div v-if="backEndError" class="mt-2 red--text">
							<div class="mt-1" v-for="(error, index) in backEndError" :key="index">
								{{ error }}
							</div>
						</div>
						<p class="mt-4">
							Sample product excel file format
							<a
								class="blue--text text--darken-4 fw-500"
								:href="$assetURL('media/import-products.xlsx')"
								target="_blank"
								download
								>download</a
							>
						</p>
						<!-- <p class="mt-4" v-if="upload_excel.type == 'service'">
							Sample service excel file format
							<a
								class="blue--text text--darken-4 fw-500"
								:href="$assetURL('media/service line-item-format.xlsx')"
								target="_blank"
								download
								>download</a
							>
						</p> -->
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					depressed
					tile
					@click="importExcelDialog = false"
				>
					Close</v-btn
				>
				<v-btn
					class="white--text"
					:disabled="pageLoading"
					:loading="pageLoading"
					depressed
					color="blue darken-4"
					tile
					@click="uploadLineItems()"
				>
					Upload File</v-btn
				>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import StatusCard from "@/view/components/statusCard.vue";
import Dialog from "@/view/components/Dialog.vue";
import FileUpload from "@/view/components/FileUpload.vue";
import { ImportProductExcel } from "@/core/lib/inventory.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "inventory-listing",
	title: "Listing Products",
	mixins: [ListingMixin],
	components: {
		StatusCard,
		Dialog,
		FileUpload,
	},
	data() {
		return {
			pageTitle: "Products",
			pageBreadcrumbs: [{ text: "Products", disabled: true }],
			endpoint: "inventory",
			defaultFilter: {},
			importExcelDialog: false,
			backEndError: [],
			fileError: null,
			upload_excel: {
				type: null,
				file: null,
			},
		};
	},
	methods: {
		changeStatus(value) {
			this.status = value;
			this.filterRows();
		},
		importExcelFile() {
			const _this = this;
			_this.importExcelDialog = true;
		},

		async uploadLineItems() {
			this.backEndError = [];
			const _this = this;
			if (!_this.upload_excel.file[0].file) {
				this.fileError = "File is required";
				return false;
			} else {
				this.fileError = null;
			}

			const formData = new FormData();
			formData.append(`excel_file`, _this.upload_excel.file[0].file);
			this.pageLoading = true;
			try {
				await ImportProductExcel(formData);
				this.filterRows();
				_this.importExcelDialog = false;
				this.$store.dispatch(SET_LOCAL_DB);
			} catch (error) {
				if (error?.data?.message?.excel_errros && error?.data?.message?.excel_errros.length) {
					this.backEndError = error?.data?.message?.excel_errros;
				} else if (error?.data?.excel_file) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error?.data?.excel_file[0] }]);
				} else {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				this.pageLoading = false;
			}
		},
	},
};
</script>
